<template>
	<v-dialog v-model="viewMode" persistent max-width="1000px">
		<template v-slot:activator="{ on }">
			<v-btn color="primary" small rounded class="mx-2" v-on="on"> <v-icon left>mdi-open-in-app</v-icon> {{ $t('offers.view') }} </v-btn>
		</template>
		<v-card rounded="xl">
			<v-card-title>
				<span class="headline">{{ $t('offers.change') }} #{{ change.index }}</span>
				<v-spacer />
				<v-btn color="primary" icon class="mx-2" @click="switchViewMode()">
					<v-icon left>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="12" lg="4" md="6" xs="12">
						<v-card>
							<v-card-title>
								<span>{{ $t('offers.aboutTheOffer') }}</span>
							</v-card-title>
							<v-card-text>
								<h4 v-if="change.category.external" :class="[changedClassStyle('externalLink')]">
									<v-icon>mdi-earth</v-icon> {{ change.externalLink }}
								</h4>
								<h3 :class="['text-uppercase', changedClassStyle('title')]">{{ change.title }}</h3>
								<p :class="[changedInfoClassStyle('type'), changedInfoClassStyle('time')]">
									{{ change.info.type.name }} - {{ change.info.time.name }}
								</p>
								<p :class="[changedInfoClassStyle('startingDate')]"><v-icon>mdi-calendar</v-icon> {{ change.info.startingDate }}</p>
								<p :class="[changedInfoClassStyle('duration')]"><v-icon>mdi-clock-outline</v-icon> {{ change.info.duration }}</p>
								<p :class="[changedInfoClassStyle('location')]">
									<v-icon>mdi-map-marker</v-icon> {{ change.info.location.city }}
									<template v-if="change.info.location.country.key == 'ES'"> - {{ change.info.location.province }}</template>
									({{ change.info.location.country.name }})
								</p>
								<p :class="[changedInfoClassStyle('description')]" style="white-space: pre-line">{{ change.info.description }}</p>
							</v-card-text>
						</v-card>
					</v-col>
					<v-col cols="12" lg="4" md="6" xs="12">
						<v-card>
							<v-card-title>
								<span>{{ $t('offers.benefits') }}</span>
							</v-card-title>
							<v-card-text>
								<p :class="[changedInfoClassStyle('salary')]"><v-icon>mdi-currency-eur</v-icon> {{ change.info.salary }}</p>
								<v-row>
									<v-col>
										<span>{{ $t('offers.thesisOptions') }}</span>
										<v-chip
											:ripple="false"
											v-for="thesisOption in change.info.thesisOptions"
											:key="thesisOption.key"
											:class="['mx-1', changedInfoClassStyle('thesisOptions')]"
										>
											{{ thesisOption.name }}
										</v-chip>
									</v-col>
								</v-row>
								<p :class="[changedInfoClassStyle('benefits')]" style="white-space: pre-line">{{ change.info.benefits }}</p>
							</v-card-text>
						</v-card>
					</v-col>
					<v-col cols="12" lg="4" md="6" xs="12">
						<v-card>
							<v-card-title>
								<span>{{ $t('offers.requisites') }}</span>
							</v-card-title>
							<v-card-text>
								<v-row>
									<v-col>
										<v-chip
											:ripple="false"
											v-for="sector in change.info.sectors"
											:key="sector.key"
											:class="['mx-1', changedInfoClassStyle('sectors')]"
										>
											{{ sector.name }}
										</v-chip>
									</v-col>
								</v-row>
								<p :class="[changedInfoClassStyle('qualifications')]" style="white-space: pre-line">{{ change.info.qualifications }}</p>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'OfferChange',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		},
		change: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			loading: false,
			viewMode: false
		}
	},
	computed: {
		...mapGetters({
			offer: 'offers/offer'
		})
	},
	methods: {
		changedClassStyle(attribute) {
			if (this.offer[attribute] != this.change[attribute]) {
				return {
					'orange--text': true,
					'text--darken-1': true
				}
			}
		},
		changedInfoClassStyle(attribute) {
			if (this.offer.info[attribute] != this.change.info[attribute]) {
				return {
					'orange--text': true,
					'text--darken-1': true
				}
			}
		},
		switchViewMode() {
			this.viewMode = !this.viewMode
		}
	}
}
</script>
